import cx from "classnames"
import { Link } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"

import { handleMenuLinkClick } from "../../helpers"
import { useLangContext } from "../../utils/lang"
import { Button } from "../button"
import { Container } from "../container"
import { LocalizedLink } from "../localized-link"
import styles from "./footer.module.css"
import fb from "./icons/facebook.svg"
import logo from "./icons/footer-logo.svg"
import user from "./icons/icon-user.svg"
import instagram from "./icons/instagram.svg"
import twitter from "./icons/twitter.svg"
import mastercard from "./icons/master-card.svg"
import maestro from "./icons/maestro.svg"
import visa from "./icons/visa.svg"
import gopay from "./icons/go-pay.svg"

export const Footer2 = () => {
  const { t } = useTranslation()
  const { lang } = useLangContext()
  return (
    <footer className={styles.footer}>
      <Container isWide={true}>
        <div className={styles.footerCols}>
          <div className={styles.footerCol}>
            <LocalizedLink to="/">
              <img src={logo} alt="logo" />
            </LocalizedLink>
            <div className={cx(styles.socials)}>
              <a
                href="https://www.facebook.com/nutritionpro.de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={fb} alt="icon" />
              </a>
              <a
                href="https://www.instagram.com/nutritionpro.de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="icon" />
              </a>
            </div>
            <div className={cx(styles.hideXS, styles.conditions)}>
              <p>{t("general.footer.terms2")}</p>
              <p>
                <LocalizedLink to="/dsgvo">
                  {t("general.footer.link")}
                </LocalizedLink>
                {" | "}
                <LocalizedLink
                  to="/terms"
                  target="_blank"
                  rel="noopener norefferer"
                >
                  {t("general.footer.terms1")}
                </LocalizedLink>
                {" | "}
                <LocalizedLink
                to="/impressum"
                target="_blank"
                rel="noopener norefferer"
              >
                {t("general.footer.link2")}
              </LocalizedLink>
              </p>
              <p>
                <span>Copyright &#169; 2022 Nutritionpro GmbH</span>
              </p>
            </div>
          </div>
          <div className={styles.footerCol}>
            <div className={cx(styles.footerMenu, styles.hideXS)}>
              <h4 className={styles.footerColTitle}>{t("general.footer.menuLinks")}</h4>
              <Button
                type="unstyled"
                handleClick={() =>
                  handleMenuLinkClick({ link: "/#programs" }, lang)
                }
              >
                {t("menu.Programy")}
              </Button>
              <Button
                type="unstyled"
                handleClick={() =>
                  handleMenuLinkClick({ link: "/#food" }, lang)
                }
              >
                {t("menu.Jídelníček")}
              </Button>
              <Button
                type="unstyled"
                handleClick={() =>
                  handleMenuLinkClick({ link: "/#calculator" }, lang)
                }
              >
                {t("menu.Cena")}
              </Button>
              <LocalizedLink to="/jobs">{t("menu.Kariéra")}</LocalizedLink>
              
            </div>
          </div>

          <div className={cx(styles.footerCol, styles.contacts)}>
            <h4 className={styles.footerColTitle}>{t("general.footer.contactCustomers")}</h4>
              <p className={styles.contactPhone}>+49 893 704 00 76</p>
              <p>info@nutritionpro.de</p>
              <p className={styles.contactAdress}>Dingolfinger Straße 15, <br/>81673 München</p>
            <h4 className={styles.footerColTitle}>{t("general.footer.contactPartners")}</h4>
              <p>partnership@nutritionpro.de</p>
          </div>
          <div className={cx(styles.footerCol, styles.hours)}>
            <h4 className={styles.footerColTitle}>{t("general.footer.hours")}</h4>
              <p>
                <span style={{ marginRight: "12px" }}>{t("menu.days")}</span> 10.00–12.00   12.00–18.00
              </p>
              <p>
                <span style={{ marginRight: "12px" }}>{t("menu.days2")}</span> Closed
              </p>
          </div>
        </div>
        <div className={cx(styles.mobileCol, styles.visibleXS, styles.conditions)}>
          <p>{t("general.footer.terms2")}</p>
            <p>
              <LocalizedLink to="/dsgvo">
                {t("general.footer.link")}
              </LocalizedLink>
              {" | "}
              <LocalizedLink
                to="/terms"
                target="_blank"
                rel="noopener norefferer"
              >
                {t("general.footer.terms1")}
              </LocalizedLink>
              {" | "}
              <LocalizedLink
                to="/impressum"
                target="_blank"
                rel="noopener norefferer"
              >
                {t("general.footer.link2")}
              </LocalizedLink>
              </p>
              <p>
                <span>Copyright &#169; 2022 Nutritionpro GmbH</span>
              </p>
        </div>
      </Container>
    </footer>
  )
}
