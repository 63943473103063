// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-de-js": () => import("./../../../src/pages/cookies/de.js" /* webpackChunkName: "component---src-pages-cookies-de-js" */),
  "component---src-pages-cookies-en-js": () => import("./../../../src/pages/cookies/en.js" /* webpackChunkName: "component---src-pages-cookies-en-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-dekovacka-testdrive-js": () => import("./../../../src/pages/dekovacka-testdrive.js" /* webpackChunkName: "component---src-pages-dekovacka-testdrive-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-dsgvo-js": () => import("./../../../src/pages/dsgvo.js" /* webpackChunkName: "component---src-pages-dsgvo-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-imunita-index-js": () => import("./../../../src/pages/imunita/index.js" /* webpackChunkName: "component---src-pages-imunita-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jidalnicek-js": () => import("./../../../src/pages/jidalnicek.js" /* webpackChunkName: "component---src-pages-jidalnicek-js" */),
  "component---src-pages-jobs-index-js": () => import("./../../../src/pages/jobs/index.js" /* webpackChunkName: "component---src-pages-jobs-index-js" */),
  "component---src-pages-jobs-job-item-js": () => import("./../../../src/pages/jobs/jobItem.js" /* webpackChunkName: "component---src-pages-jobs-job-item-js" */),
  "component---src-pages-jobs-sales-js": () => import("./../../../src/pages/jobs/sales.js" /* webpackChunkName: "component---src-pages-jobs-sales-js" */),
  "component---src-pages-novemenu-js": () => import("./../../../src/pages/novemenu.js" /* webpackChunkName: "component---src-pages-novemenu-js" */),
  "component---src-pages-novinky-js": () => import("./../../../src/pages/novinky.js" /* webpackChunkName: "component---src-pages-novinky-js" */),
  "component---src-pages-order-index-js": () => import("./../../../src/pages/order/index.js" /* webpackChunkName: "component---src-pages-order-index-js" */),
  "component---src-pages-products-product-1-js": () => import("./../../../src/pages/products/product-1.js" /* webpackChunkName: "component---src-pages-products-product-1-js" */),
  "component---src-pages-products-product-2-js": () => import("./../../../src/pages/products/product-2.js" /* webpackChunkName: "component---src-pages-products-product-2-js" */),
  "component---src-pages-products-product-3-js": () => import("./../../../src/pages/products/product-3.js" /* webpackChunkName: "component---src-pages-products-product-3-js" */),
  "component---src-pages-products-product-4-js": () => import("./../../../src/pages/products/product-4.js" /* webpackChunkName: "component---src-pages-products-product-4-js" */),
  "component---src-pages-program-for-companies-index-js": () => import("./../../../src/pages/program-for-companies/index.js" /* webpackChunkName: "component---src-pages-program-for-companies-index-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thank-you-affiliate-js": () => import("./../../../src/pages/thank-you-affiliate.js" /* webpackChunkName: "component---src-pages-thank-you-affiliate-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-order-js": () => import("./../../../src/pages/thank-you-order.js" /* webpackChunkName: "component---src-pages-thank-you-order-js" */),
  "component---src-pages-ukazka-jidelnicku-js": () => import("./../../../src/pages/ukazka-jidelnicku.js" /* webpackChunkName: "component---src-pages-ukazka-jidelnicku-js" */),
  "component---src-pages-vanoce-index-js": () => import("./../../../src/pages/vanoce/index.js" /* webpackChunkName: "component---src-pages-vanoce-index-js" */),
  "component---src-pages-voucher-index-js": () => import("./../../../src/pages/voucher/index.js" /* webpackChunkName: "component---src-pages-voucher-index-js" */)
}

