import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import FingerprintJS from "@fingerprintjs/fingerprintjs";

import { getUTM, setCookie, getCookie } from '../helpers';

export const initialState = {
  isAffiliate: false,
  affiliateId: null,
}

const AffiliateContext = createContext(initialState)

const AffiliateProvider = ({ children }) => {
  const [affiliate, dispatch] = useReducer(affiliateReducer, initialState);
  const [visitorId, setVisitorId] = useState();

  // const keyWord = "cjevent"; // = affiliate
  // const UTMS = getUTM();
  // const hasKeyWord = UTMS && Object.values(UTMS).some(value => value && value.includes(keyWord));
  const Global = typeof document !== `undefined` && document;
  let urlString = Global?.location?.href
  let url = urlString && new URL(urlString)
  const keyWord = url && url.searchParams.get("cjevent")
  const hasKeyWord = !!keyWord
  const cjeCoockie = getCookie("cje");
  
  const handleSetAffiliate = useCallback(() => {
    console.log("hasKeyWord", hasKeyWord);
    if (hasKeyWord) {
      const cjevent = keyWord;
      console.log("POST cjevent", cjevent);
      postCjevent({
        "fp": String(visitorId),
        "cjevent": String(cjevent)
      }).then(res => res.json()).then(data => {
        console.log("POST cjevent data", data);
        if (!!data.success) {
          console.log("POST cjevent data success", cjevent);
          setCookie("cje", cjevent, { secure: true });
          dispatch({type: "AFFILIATE_ON", payload: data.result.cjevent});
        } else {
          console.log("POST cjevent data error - remove cjevent");
          removeCjeventData()
          dispatch({type: "AFFILIATE_OFF"});
        }
      });
    } else {
      console.log("GET cjevent");
      console.log("visitorId param", visitorId);
      getCjevent(visitorId).then(res => res.json()).then(data => {
        console.log("GET cjevent data", data);
        if (data.success === true && data.result.cjevent !== null) {
          console.log("GET cjevent success", data.result.cjevent);
          // setCookie("cje", data.result.cjevent);
          dispatch({type: "AFFILIATE_ON", payload: data.result.cjevent});
        } else {
          console.log("GET cjevent error");
          removeCjeventData()
          dispatch({type: "AFFILIATE_OFF"});
        }
      });
    }
  }, [hasKeyWord, visitorId]);

  const initPrint = () => {
    console.log("run initPrint");
    FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => {
          setVisitorId(result.visitorId);
      });
  };

  const postCjevent = (data) =>
    fetch(`${process.env.GATSBY_APP_API_URL}/affiliate/visitor`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })

  const getCjevent = (visitorId) => 
    fetch(`${process.env.GATSBY_APP_API_URL}/affiliate/visitor/${visitorId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
  
  useEffect(() => {
    initPrint()
  }, [])

  useEffect(() => {
    console.log("visitorId", visitorId);
    if (visitorId) {
      handleSetAffiliate();
    }
  }, [visitorId]);

  return (
    <AffiliateContext.Provider
      value={{
        affiliate,
        dispatch,
      }}
    >
      {children}
    </AffiliateContext.Provider>
  );
};

function removeCjeventData() {
  if (typeof document !== `undefined`) {
    console.log("gonna clear cje scripts")
    const scripts = document.querySelectorAll('[data-cje]');
    Array.from(scripts).map(script => {
      script.innerHTML = "";
    })
  }
}

export { AffiliateProvider, AffiliateContext };

export const affiliateReducer = (state, action) => {
  switch (action.type) {
    case "AFFILIATE_ON":
      return { isAffiliate: true, affiliateId: action.payload }
    case "AFFILIATE_OFF":
      return { isAffiliate: false, affiliateId: null }
    default:
      return state
  }
}
